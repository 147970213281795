<template>
    <div
        v-if="show"
        class="modal-window"
        role="alert"
    >
        <div class="edit-purchase-modal w-600px bg-none mx-auto position-fixed top-0 mt-auto">
            <div
                :class="editingMultiple === true ? 'has-multiple' : ''"
                class="edit-purchase-modal-content-wrapper bg-pso-primary py-12 px-8 rounded-xl shadow"
            >
                <header>
                    <h4 class="edit-purchase-modal-heading text-white font-medium text-2xl mb-4">
                        {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.title') }}
                    </h4>
                </header>

                <div
                    v-if="initialized"
                    class="edit-purchase-modal-wrapper"
                >
                    <div class="mb-6">
                        <label
                            class="block mb-2 text-lg text-white"
                            dusk="label-personnel-number"
                            for="personnel_number_label"
                        >
                            {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.product_name_label') }}
                        </label>

                        <TextInput
                            v-model="form.product_name"
                            id="product_name"
                            name="product_name"
                            :main-errors="errors.product_name"
                            :placeholder="translate('calculation_tool_data.purchase.edit.edit_form_modal.product_name_placeholder')"
                        >
                            <ModalSave
                                :cancel-function="cancelEditing"
                                :save-function="saveChanges"
                            />
                        </TextInput>

                        <span
                            v-if="errors.product_name"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.product_name"
                        />
                    </div>
                    <div
                        class="mb-6"
                    >
                        <span class="text-lg text-white inline-block">
                            {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.certified_organisation_label') }}
                        </span>

                        <MultiSelectInput
                            id="organisation_id"
                            v-model="form.organisation_id"
                            name="organisation_id"
                            :errors="errors.organisation_id"
                            :options="certifiedOrganisationsOptions"
                            :groups="true"
                            group-label="label"
                            group-values="options"
                            :can-clear="true"
                            :searchable="true"
                            dusk="modal-edit-tge-btn-multiselect-contract-type"
                            :placeholder="translate('calculation_tool_data.purchase.edit.edit_form_modal.certified_organisation_placeholder')"
                        >
                        </MultiSelectInput>

                        <span
                            v-if="errors.organisation_id"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.organisation_id"
                        />
                    </div>
                    <div
                        class="mb-6"
                    >
                        <label
                            class="block mb-2 text-lg text-white"
                            for="value_of_purchase"
                        >
                            {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.value_of_purchase_label') }}
                        </label>

                        <NumberInput
                            v-model="form.value_of_purchase"
                            id="value_of_purchase"
                            name="value_of_purchase"
                            :errors="errors.value_of_purchase"
                            :has-decimals="true"
                            :placeholder="translate('calculation_tool_data.purchase.edit.edit_form_modal.value_of_purchase_placeholder')"
                        >
                        </NumberInput>
                        <span
                            v-if="errors.value_of_purchase"
                            class="text-sm text-red-500"
                            role="alert"
                            v-text="errors.value_of_purchase"
                        />
                    </div>
                </div>

                <footer class="confirmation-modal-footer flex items-center justify-start">
                    <button
                        class="inline-block text-white font-medium py-3 px-6 rounded-xl cursor-pointer bg-pso-orange"
                        :class="allRequiredAreFilled ? '' : 'opacity-50 cursor-not-allowed'"
                        dusk="modal-edit-tge-btn-modal-confirm-action"
                        @click="saveChanges()"
                    >
                        {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.save_button') }}
                    </button>

                    <button
                        class="inline-block text-pso-tertiary underline py-3 px-6 cursor-pointer"
                        dusk="modal-edit-tge-btn-modal-cancel-action"
                        @click="cancelEditing()"
                    >
                        {{ translate('calculation_tool_data.purchase.edit.edit_form_modal.cancel_button') }}
                    </button>
                </footer>
            </div>
            <div class="edit-purchase-modal-shadow"></div>
        </div>
    </div>
</template>

<script setup>
import {useStore} from 'vuex';
import Links from "@_config/Links.js"
import {onBeforeMount, onBeforeUnmount, onUnmounted, ref, watch} from "vue";
import translation from "@Mixins/Translation";
import TextInput from "../../../../FormInput/TextInput/Vue/TextInput.vue";
import MultiSelectInput from "../../../../FormInput/MultiSelectInput/Vue/MultiSelectInput.vue";
import NumberInput from "../../../../FormInput/NumberInput/Vue/NumberInput.vue";
import CalculationToolData from "../../../CalculationToolData";

const { translate } = translation.methods;
const store = useStore();

const DEFAULT_FORM_VALUES = {
    supplier_certification: null,
    organisation_id: null,
    value_of_purchase: null,
    product_name: null,
};

const props = defineProps({
    selectedItemsToEdit: {
        type: Object,
        default: {}
    },
});

const links = ref(Links);
const show = ref(false);
const editingMultiple = ref(false);
const allRequiredAreFilled = ref(false);
const itemsToEdit = ref({});
const currentlyEditing = ref({});
const form = ref(Object.assign({}, DEFAULT_FORM_VALUES));
const calculationToolData = ref({});
const errors = ref({});
const initialized = ref(false);
const calculationToolId = ref(null);
const certifiedOrganisationsOptions = ref(null);
const certifiedOrganisationMapping= [];

const iconInfo = new URL('/resources/images/icon_info.svg', import.meta.url).href;

watch(form, value => {
    validateRequiredFields();
}, {
    deep: true
});

onBeforeMount(() => {
    window.mitt.on('showPurchaseEditModal', onShowEditModal);
})

onBeforeUnmount(() => {
    window.mitt.off('showPurchaseEditModal', onShowEditModal);
})

function onShowEditModal(modalData) {
    itemsToEdit.value = modalData.selectedItemsToEdit;
    calculationToolId.value = modalData.calculationToolId;
    calculationToolData.value = modalData.calculationToolData;
    certifiedOrganisationsOptions.value = modalData.certifiedOrganisationsOptions;
    certifiedOrganisationMapping.value = modalData.certifiedOrganisationMapping;
    initialized.value = true;
    showModal();
}

function showModal() {
    editingMultiple.value = itemsToEdit.value.length > 1;
    editPurchase();
    show.value = true;
}

function hideModal() {
    CalculationToolData.getPurchaseList(calculationToolId.value);
    editingMultiple.value = false;
    currentlyEditing.value = {};
    form.value = {};
    errors.value = {};
    show.value = false;
}

function  validateRequiredFields() {
    let requiredKeys = ['organisation_id', 'value_of_purchase', 'product_name'];
    allRequiredAreFilled.value = !requiredKeys.some(function (key) {
        return !Boolean(form.value[key]);
    }.bind(this));
}

function  saveChanges() {
    validateRequiredFields();
    if (allRequiredAreFilled.value === false) {
        return;
    }

    errors.value = {};
    form.value.supplier_certification = updateSelectedOrganisationCertificationId(form.value.organisation_id);
    let formData = CalculationToolData.buildPurchasesForm(form.value, calculationToolData.value);
    CalculationToolData.updatePurchase(formData)
        .then(() => {
            editPurchase();
        })
        .catch((error) => {
            if (error.response) {
                if (error.response) {
                    let errorFields = Object.keys(error.response.data.errors);

                    errorFields.map(field => {
                        errors.value[field] = error.response.data.errors[field][0];
                        if(typeof errors.value[field] === 'object' && errors.value[field] !== null) {
                            let errorSubFields = Object.keys(errors.value[field]);
                            errorSubFields.map(subField => {
                                errors.value[field] = errors.value[field][subField];
                            });
                        }
                    });
                }
            }
        });
}

function  cancelEditing() {
    allRequiredAreFilled.value = false;
    editPurchase();
}

function  editPurchase() {
    if (itemsToEdit.value.length === 0) {
        hideModal();
        return;
    }

    errors.value = {};
    currentlyEditing.value = itemsToEdit.value.shift();

    form.value = Object.assign({}, currentlyEditing.value);

    form.value.supplier_certification = currentlyEditing.value.supplier_certification;
    form.value.organisation_id = currentlyEditing.value.organisation.id;
    const formattedValue = form.value.value_of_purchase;

    if (formattedValue) {
        // Remove the thousand separator and replace the decimal comma with a dot
        form.value.value_of_purchase = parseFloat(formattedValue.replace(/[.,]/g, ''));
    }

    validateRequiredFields();
}

function updateSelectedOrganisationCertificationId(selectedOrganisationId) {
    let certificationId = null;

    // Iterate over the certifiedOrganisationMapping object
    for (const [key, value] of Object.entries(certifiedOrganisationMapping.value)) {
        // Check if the organisation_id matches the selected one
        if (value.organisation_id === selectedOrganisationId) {
            certificationId = value.certification_id;
            break;
        }
    }

    return certificationId;
}


</script>
